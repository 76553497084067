import { axiosAccountInstance } from "api/Request";



export const getScheduledInterviews = () => (dispatch) => {
    debugger
//   let url = `/interview`
//   url = getSearchUrl(url);
  dispatch({
    type: "SCHEDULED_INTERVIEW_LIST_BEGINS",
  });

  axiosAccountInstance
    .get(`/interview`)
    .then((result) => {
      // dispatching success
      dispatch({
        type: "SCHEDULED_INTERVIEW_LIST_SUCCESS",
        payload: result.data.body.data,
      });
      // if (result.data.statusCode === 200) {
      //   return dispatch({
      //     type: "SCHEDULED_INTERVIEW_LIST_SUCCESS",
      //     payload: result.data.body.data,
      //   });
      // }
    })
    
    .catch((err) => {
      dispatch({
        type: "SCHEDULED_INTERVIEW_LIST_FAILURE",
        payload: "FALIURE",
      });
    });
};

