import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import pendingIcon from "../../assets/img/icons/customIcons/pendingIcon.svg";
import extensionIcon from "../../assets/img/icons/customIcons/pendingExtn.svg";
import DashboardHeader from "components/Headers/DashboardHeader";
import { getResumeRequests } from "redux/actions/admin/ResumeRequestsAction";
import PendingSOWApprovalList from "./admin/company/company-pending-SOW-approval/PendingSOWApprovalList";
import CompanyBookingExtentionPendingList from "./admin/company/company-bookingextentionpending-request/CompanyBookingExtentionPendingList";
import BookingDetails from "./users/BookingDetailsContainer";
import BookingExtentionDetails from "./users/BookingExtentionDetails";

import InboundResumeRequestList from "./admin/resume-request-list/InboundResumeRequestList";
import OutboundResumeRequestList from "./admin/resume-request-list/OutboundResumeRequestList";


function ResumeList(state) {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getResumeRequests("0", "", ""));
  // }, [dispatch]);

  const notification = useSelector((state) => state.NotificationReducer.result);
  console.log("my tab and tile", state);

  const [activeTab, setActiveTab] = useState(
    state?.location?.state?.tile
      ? state?.location?.state?.tile
      : notification?.tile
      ? notification?.tile
      : "1"
  );
  const getInboundResumeList = (createdBy, page, searchData) => {
    dispatch(getResumeRequests(createdBy, page, searchData));
  };

  const getOutboundResumeList = (createdBy, page, searchData) => {
    dispatch(getResumeRequests(createdBy, page, searchData));
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      switch (tab) {
        case "1":
          getInboundResumeList("0", "1", "");

          break;
        case "2":
          getOutboundResumeList("1", "1", "");

          break;

        default:
          break;
      }

      setActiveTab(tab);
    }
  };
  const data = [
   
    {
      title: "Inbound Requests",
      tabId: "1",
      count: null,
      icon: pendingIcon,
      color: "blue",
    },
    {
      title: "Outbound Requests",
      tabId: "2",
      count: null,
      icon: extensionIcon,
      color: "pink",
    },
  
  ];

  useEffect(() => {
    console.log("out If ", notification);
    if (notification?.tile) {
      console.log("in If");
      setActiveTab(notification?.tile);
    }
  }, [notification]);
  return (
    <div>
      <DashboardHeader
        tabCards={data}
        selectedCard={(tabId) => toggle(tabId)}
        activeTab={activeTab}
      />

      {activeTab === "1" && (
        // <EmployeeSearchList
        //   empId={
        //     state?.location?.state?.empId ? state?.location?.state?.empId : null
        //   }
        // />
        <InboundResumeRequestList state={state}/>
      )}

      {activeTab === "2" && (
        <OutboundResumeRequestList state={state}/>
        // <BookingExtentionDetails
        //   empId={
        //     state?.location?.state?.empId ? state?.location?.state?.empId : null
        //   }
        // />
      )}

     
    </div>
  );
}

export default ResumeList;
