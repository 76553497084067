import React, { useEffect, useState } from "react";
import ScheduledInterviewListCard from "./ScheduledInterviewListCard";
import { Row, Col, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
// import PaginationComponent from "components/pagination/Pagination";
// import { setCompanyApprovedRequestsInfo } from "redux/actions/super-admin/ComapnyApprovedAction";

function ScheduledInterviewList({
//   handleEmployeeSearchOnChange,
//   handleSearch,
//   searchFormData,
//   setSearchFormData,
  data,
  fromSuperAdmin,
}) {
  const dispatch = useDispatch();
  
  const scheduledInterviewListLoader = useSelector(
    (state) => state.ScheduledInterviewReducer?.loader)
    const scheduledInterviewListResult = useSelector(
      (state) => state.ScheduledInterviewReducer?.result
    );
  return(
    <>
     <Container className="mt-2 main-contentarea" fluid>
          <Row>
            {/* <Col id="tableDiv" md={isFilter ? "9" : "12"}> */}
            <Col id="tableDiv" md="12">
              <Row>
                <Col md="10">
                  <div className="d-flex justify-content-between">
                    <h3 class="tab-title">Interview Listing</h3>
                  </div>
                </Col>

                {/* <Col
                  md="2"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div className="common-outer-filter">
                    <div className="btn-group new-cutom-filter ">
                      <button
                        type="button"
                        onClick={() => setIsFilter(!isFilter)}
                        className={"btn dropdown-toggle"}
                      >
                        <img alt="" src={SearchIcon} width={"18px"} />
                      </button>
                    </div>
                  </div>
                </Col> */}
              </Row>

              <ScheduledInterviewListCard
                // searchFormData={{ ...searchFormData, page: tab }}
                // companyId={companyId}
                loader={scheduledInterviewListLoader}
              />

              <div
                style={{ display: scheduledInterviewListLoader ? "none" : "contents" }}
              >
                {/* <PaginationComponent
                  totalCount={scheduledInterviewListResult?.itemCount}
                  handlePagination={handlePagination}
                  activePage={scheduledInterviewListResult?.activePage}
                /> */}
              </div>
            </Col>

              {/* <Col
                md="3"
                style={{
                  height: tableHeight,
                  minHeight: "308px",
                  display: isFilter ? "block" : "none",
                }}
              >
                <CustomFilter
                  tableHeight={tableHeight}
                  isFilter={isFilter}
                  setIsFilter={setIsFilter}
                  clearDate={clearDate}
                  type="manager"
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                />
              </Col>
             */}
          </Row>
        </Container>
    </>
  )
}

export default ScheduledInterviewList;